import revive_payload_client_z2XANegaGK from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7bdxze5eqvq5akklqqieia6q2e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_81fEuVEHC6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7bdxze5eqvq5akklqqieia6q2e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7eHyOujLRZ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7bdxze5eqvq5akklqqieia6q2e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8s87o2LsJN from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.17_magicast@0.3.5_rollup@4.21.2_vite@5.4.4_@types+node@20.16.5_terser@5._wp2z5zpyayr43j3m2nj55uqcbi/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_1CfEiYed0T from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7bdxze5eqvq5akklqqieia6q2e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tipqHPw5fB from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7bdxze5eqvq5akklqqieia6q2e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Et1VOTEVlL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7bdxze5eqvq5akklqqieia6q2e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_PX6H6nh8yg from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.6.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_9isE9IZxpC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7bdxze5eqvq5akklqqieia6q2e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_c46rdeenLb from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import motion_6lL0WTYnOF from "/opt/build/repo/node_modules/.pnpm/@vueuse+motion@2.2.5_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import titles_ncKwh02Rv5 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.4.2_rollup@4.21.2_vite@5.4.4_@types+node@20.16.5_ter_lxbkbtlyadurlghvgbhhc3hyoq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_FNN0Q6PKbT from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.4.2_rollup@4.21.2_vite@5.4.4_@types+node@20.16.5_ter_lxbkbtlyadurlghvgbhhc3hyoq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import plugin_Qa1h2am6Do from "/opt/build/repo/node_modules/.pnpm/@storyblok+nuxt@6.0.10/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import _01posthog_client_MKLKwXG2uG from "/opt/build/repo/plugins/01posthog.client.ts";
import _02cookiefirst_client_napE60kAZE from "/opt/build/repo/plugins/02cookiefirst.client.ts";
import _03registerSwiper_XLkAI2dy4M from "/opt/build/repo/plugins/03registerSwiper.ts";
import _04sentry_client_e8XkWREx89 from "/opt/build/repo/plugins/04sentry.client.ts";
import _05chunkReloadError_UE8EuDbuyH from "/opt/build/repo/plugins/05chunkReloadError.ts";
export default [
  revive_payload_client_z2XANegaGK,
  unhead_81fEuVEHC6,
  router_7eHyOujLRZ,
  _0_siteConfig_8s87o2LsJN,
  payload_client_1CfEiYed0T,
  navigation_repaint_client_tipqHPw5fB,
  check_outdated_build_client_Et1VOTEVlL,
  plugin_vue3_PX6H6nh8yg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9isE9IZxpC,
  i18n_c46rdeenLb,
  motion_6lL0WTYnOF,
  titles_ncKwh02Rv5,
  defaults_FNN0Q6PKbT,
  plugin_Qa1h2am6Do,
  _01posthog_client_MKLKwXG2uG,
  _02cookiefirst_client_napE60kAZE,
  _03registerSwiper_XLkAI2dy4M,
  _04sentry_client_e8XkWREx89,
  _05chunkReloadError_UE8EuDbuyH
]